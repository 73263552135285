<template>
  <div>
    <v-subheader>{{ $t("preferences") | capitalize }}</v-subheader>

    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-email-alert</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-switch
          v-model="notify"
          class="mt-0 ml-3"
          label="Notification email pour nouvelle alerte"
          @change="setNotification($event)"
        ></v-switch>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      notifyState: (state) => state.account.user.notify,
    }),

    notify: {
      get(){
        return this.notifyState;
      },
      set(newState){
        return newState;
      }
    }
  },

  methods: {
    setNotification(notifyState) {
      this.$store.dispatch("account/setMailNotification", notifyState);
    },
  }
}
</script>
